import React from "react";
import { withStyles } from "@material-ui/core";
import { colorPalette } from "./colors";

const styles = theme => ({
  root: {
    marginTop: "calc(2vh + 2vw)",
    display: "grid",
    gridGap: "calc(0.7vh + 0.7vw)",
    position: "relative",
    "&:before": {
      top: "calc(-0.7vh - 0.7vw)",
      position: "absolute",
      content: "\"\"",
      borderTop: `4px solid ${colorPalette.orange}`,
      width: "50%",

      "& $rightImage": {
        right: 0
      }
    },

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr !important",
      gridTemplateAreas: "\"image\" \"content\" !important"
    }

  },

  leftImage: {
    gridTemplateColumns: "30vw 1fr",
    gridTemplateAreas: "\"image content\""
  },

  rightImage: {
    gridTemplateColumns: "1fr 30vw",
    gridTemplateAreas: "\"content image\"",
    textAlign: "right",
    "&:before": {
      right: 0
    }
  },

  content: {
    gridArea: "content",
    marginBottom: "calc(4vw + 4vh)",
    "& h2": {
      fontSize: "calc(4vw + 4vh)",
      fontWeight: 700,
      lineHeight: "calc(4vw + 4vh)",
      marginBlockStart: 0,
      marginBlockEnd: 0
    },

    "& h3": {
      color: colorPalette.blue
    }
  },

  image: {
    gridArea: "image",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },


  sroot: {
    display: "flex",
    flexDirection: "row",
    padding: "16px",
    [theme.breakpoints.up("sm")]: {
      height: "40vh"
    }


  }
});

const HistoryItemView = ({ classes, historyItem, textFirst }) => (
  <div className={`${classes.root} ${textFirst ? classes.rightImage : classes.leftImage}`}>
    <div className={classes.content}>
      <h2>{historyItem.date}</h2>
      <h3>{historyItem.title}</h3>
      <p>{historyItem.desc}</p>
    </div>
    <img className={classes.image}
         alt=""
         src={historyItem.image}
    />
  </div>
);

export default withStyles(styles)(HistoryItemView);
