import React from "react";
import { withStyles } from "@material-ui/core";
import SubPageBase from "../../components/SubPageBase";
import HistoryItemView from "../../components/HistoryItemView";
import titleImg from "../../images/history.jpg";
import img1976 from "../../images/history_item.png";
import img2006 from "../../images/biz/h2006.jpg";
import img2009 from "../../images/biz/h2009.jpg";
import img2010 from "../../images/biz/h2010.jpg";
import img2013 from "../../images/biz/h2013.jpg";
import img2015 from "../../images/biz/h2015.png";
import img2017 from "../../images/biz/h2017.png";
import img2018 from "../../images/biz/h2018.jpg";
import img2019 from "../../images/biz/h2019.png";

const styles = (theme) => ({
  root: {
    fontSize: "1.5em",
  },
});

const historyItems = [
  {
    date: "1976",
    title: "İlk Decathlon",
    desc: "Fransa Englos’ta, ilk Decathlon mağazası açıldı.",
    image: img1976,
  },
  {
    date: "2006",
    title: "Türkiye'de Üretim",
    desc: "Türkiye'deki faaliyetlerimize ihracat amaçlı üretim ile başladık.",
    image: img2006,
  },
  {
    date: "2009",
    title: "Genel Merkez",
    desc: "2 yıllık bir pazar araştırmasının ardından, İstanbul'da ofisimizi açtık.",
    image: img2009,
  },
  {
    date: "2010",
    title: "İlk Mağaza: Ankara",
    desc:
      "Türkiye'deki ilk mağazamızı Kentpark'ta açtık. Kısa bir süre sonra Forum İstanbul'da İstanbul'un ilk mağazasını açtık.",
    image: img2010,
  },
  {
    date: "2013",
    title: "www.decathlon.com.tr",
    desc: "İnternet mağazamızı açarak tüm Türkiye'de hizmet vermeye başladık.",
    image: img2013,
  },
  {
    date: "2015",
    title: "Hissedarlık",
    desc: "Tüm takım arkadaşlarımızın Decathlon'dan hisse alabilecekleri hissedarlık sistemini Türkiye'ye açtık.",
    image: img2015,
  },
  {
    date: "2017",
    title: "Kar Paylaşımı",
    desc: "Decathlon'da 1 yılını doldurmuş tüm takım arkadaşlarımızla kârımızı paylaştık.",
    image: img2017,
  },
  {
    date: "2018",
    title: "Great Place To Work",
    desc:
      "2016'da aldığımız Türkiye'nin en iyi 5. işvereni ödülünün ardından, 2018'de Türkiye'nin en iyi 2. işvereni olduk.",
    image: img2018,
  },
  {
    date: "2023",
    title: "Büyüyoruz",
    desc:
      "Türkiye'de; 42 mağazamız, websitemiz ve yeni kanallarımız ile sporu ulaşılabilir kılmak için çalışmaya devam ediyoruz.",
    image: img2019,
  },
];

const IndexPage = ({ classes }) => (
  <SubPageBase title={"TARİHÇEMİZ"} img={titleImg} className={classes.root}>
    {historyItems.map((historyItem, index) => (
      <HistoryItemView historyItem={historyItem} textFirst={index % 2 === 0} key={historyItem.date} />
    ))}
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
